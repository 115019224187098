import React, { useState } from 'react';
import axios from 'axios';
import { Search, Mail, AlertCircle } from 'lucide-react';

const App = () => {
  const [url, setUrl] = useState('');
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setEmails([]);

    try {
      const response = await axios.post('http://38.174.113.176:8000/lookup_email', { url });
      setEmails(response.data.emails);
    } catch (error) {
      setError(error.response?.data?.detail || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 to-indigo-200 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl p-8 w-full max-w-md">
        <h1 className="text-3xl font-bold text-center mb-6 text-indigo-700">Email Lookup</h1>
        <form onSubmit={handleSubmit} className="mb-6">
          <div className="relative">
            <input
              type="text"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="Enter YouTube or TikTok URL"
              className="w-full p-4 pr-12 text-gray-700 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-400"
            />
            <Search className="absolute right-3 top-3 text-gray-400" size={24} />
          </div>
          <button
            type="submit"
            disabled={loading}
            className={`mt-4 w-full p-4 text-white rounded-lg shadow-md transition-all duration-300 ease-in-out ${
              loading
                ? 'bg-indigo-400 cursor-not-allowed'
                : 'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50'
            }`}
          >
            {loading ? (
              <span className="flex items-center justify-center">
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Loading...
              </span>
            ) : (
              'Lookup'
            )}
          </button>
        </form>
        {error && (
          <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4 rounded" role="alert">
            <div className="flex">
              <AlertCircle className="flex-shrink-0 mr-3" size={24} />
              <div>{error}</div>
            </div>
          </div>
        )}
        {emails.length > 0 && (
          <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 rounded">
            <h2 className="text-xl font-semibold mb-2 flex items-center">
              <Mail className="mr-2" size={24} />
              Found Emails:
            </h2>
            <ul className="list-disc pl-5">
              {emails.map((email, index) => (
                <li key={index} className="mb-1 break-all">{email}</li>
              ))}
            </ul>
          </div>
        )}
        {emails.length === 0 && !loading && !error && (
          <p className="text-center text-gray-600">No emails found. Try a different URL.</p>
        )}
      </div>
    </div>
  );
};

export default App;